import styled, { DefaultTheme, keyframes, css } from 'styled-components'
import { space } from 'styled-system'
import { Box } from '../Box'
import { CardProps } from './types'

const PromotedGradient = keyframes`
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
`

interface StyledCardProps extends CardProps {
  theme: DefaultTheme
}

/**
 * Priority: Warning --> Success --> Active
 */

export const StyledCard = styled.div<StyledCardProps>`
  background: #ffffff;
  box-shadow: 0px 11px 38px rgba(96, 16, 30, 0.08);
  border-radius: 20px;
  color: #343434;
  overflow: hidden;
  position: relative;

  /* ${({ isActive }) =>
    isActive &&
    css`
      animation: ${PromotedGradient} 3s ease infinite;
      background-size: 400% 400%;
    `} */

  ${space}
`

export const StyledCardInner = styled(Box)<{ background?: string; hasCustomBorder: boolean }>`
  width: 100%;
  height: 100%;
  background: ${({ theme, background }) => background ?? theme.card.background};
`

StyledCard.defaultProps = {
  isActive: false,
  isSuccess: false,
  isWarning: false,
  isDisabled: false,
}
