import React, { FC } from 'react'
import { useTranslation } from 'contexts/Localization'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'

import { NotRegisteredHeroIcon } from 'views/Referral/Icons'
import { H1, P } from 'components/Text'

// ui
import { Button, useWalletModal, Link } from 'uikit'

import useAuth from 'hooks/useAuth'

const Wrap = styled.div`
  min-width: 800px;
  position: relative;
  margin-top: 12px;
  background: #ffffff;
  border-radius: 16px;
  padding: 45px 28px;
  margin-bottom: 48px;
  display: flex;
  justify-content: space-between;
`

const BackGround = styled.div`
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 320px;
  }
  &::after {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 1114 261' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1114 29.4849C1013.92 106.134 858.253 167.422 685.845 207.587C483.899 254.633 258.783 272.744 72.6897 252.528C47.7098 249.815 23.4295 246.41 0 242.291V240.26C23.4866 244.398 47.8391 247.817 72.9058 250.54C258.737 270.727 483.624 252.643 685.391 205.639C858.439 165.325 1014.33 103.773 1114 26.9634V29.4849ZM990.668 0H986.571C904.889 45.525 811.979 84.0587 713.774 115.188C487.024 187.062 232.245 219.396 22.7636 207.235C15.1139 206.791 7.52483 206.288 0 205.725V207.731C7.48756 208.29 15.038 208.79 22.6478 209.232C232.39 221.408 487.412 189.037 714.378 117.094C813.899 85.5482 908.066 46.3809 990.668 0ZM0 184.846C26.9729 187.235 55.933 187.499 86.5182 185.775C256.082 186.216 453.067 150.76 630.158 90.466C706.123 64.6025 778.456 34.1586 843.409 0H839.099C775.148 33.3735 704.109 63.1752 629.514 88.5726C463.011 145.262 278.933 179.959 116.709 183.482C164.483 178.954 215.703 169.984 269.118 157.052C398.468 125.734 540.772 71.1536 678.309 0H673.949C537.615 70.2161 396.755 124.091 268.648 155.108C203.901 170.784 142.433 180.617 86.4673 183.775C73.0656 183.74 59.836 183.48 46.802 182.99C30.8941 182.391 15.2793 181.45 0 180.157V182.164C14.2775 183.37 28.8436 184.269 43.6635 184.869C28.599 184.756 14.0274 184.086 0 182.838V184.846Z' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='1257.09' y1='-447.277' x2='227.261' y2='121.107' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FD4566' stop-opacity='0.4'/%3E%3Cstop offset='1' stop-color='%23FD4566' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: 100%;
    background-repeat: no-repeat;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Text = styled(P)`
  max-width: 463px;
  margin: 16px 0 30px;
`

const Percent = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
`

export const HeroWrap: FC = ({ children }): JSX.Element => {
  const { login, logout } = useAuth()
  const { account } = useWeb3React()
  const { t } = useTranslation()
  const { onPresentConnectModal } = useWalletModal(login, logout, t, account)

  return (
    <Wrap>
      <div>
        <BackGround />
        <H1>
          Invite your friends.
          <br />
          Eran cryptocurrency together
        </H1>
        <Text>
          Earn a certain commission reward from your friends swaps on Polepup and <Percent>5%</Percent> from their
          earnings on Farms &amp; Launchpools
        </Text>

        {account ? (
          <Link
            href="/#" // !TODO
            color="primary"
            target="_blank"
            className="infoInstruction-block__link"
            style={{ position: 'relative', zIndex: 1 }}
          >
            Read more
          </Link>
        ) : (
          <Button
            style={{ position: 'relative', zIndex: 1 }}
            variant="primary"
            scale="sm"
            width="309px"
            onClick={() => onPresentConnectModal()}
          >
            Invite friends
          </Button>
        )}
      </div>
      {children}
    </Wrap>
  )
}
