import { NavTheme } from './types'

export const light: NavTheme = {
  background: 'blue',
  hover: '#EEEAF4',
}

export const dark: NavTheme = {
  background: 'pink',
  hover: '#473d5d',
}
