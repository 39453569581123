import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.09639 8.4887C3.40231 9.05854 3 9.90934 3 10.8074V17.9999C3 19.6568 4.34315 20.9999 6 20.9999H18C19.6569 20.9999 21 19.6568 21 17.9999V10.8074C21 9.90934 20.5977 9.05854 19.9036 8.4887L14.5381 4.08372C13.0628 2.87248 10.9372 2.87248 9.46186 4.08372L4.09639 8.4887ZM11 13.9999H13C14.1046 13.9999 15 14.8953 15 15.9999V18.9999C15 20.1044 14.1046 20.9999 13 20.9999H11C9.89543 20.9999 9 20.1044 9 18.9999V15.9999C9 14.8953 9.89543 13.9999 11 13.9999Z"
        fill="currentColor"
      />
      <path
        d="M4.09639 8.4887L4.73093 9.26159H4.73093L4.09639 8.4887ZM19.9036 8.4887L20.5381 7.71581L19.9036 8.4887ZM14.5381 4.08372L13.9036 4.85661V4.85661L14.5381 4.08372ZM9.46186 4.08372L10.0964 4.85661L9.46186 4.08372ZM4 10.8074C4 10.2087 4.2682 9.64148 4.73093 9.26159L3.46186 7.71581C2.53641 8.47559 2 9.61 2 10.8074H4ZM4 17.9999V10.8074H2V17.9999H4ZM6 19.9999C4.89543 19.9999 4 19.1045 4 17.9999H2C2 20.2091 3.79086 21.9999 6 21.9999V19.9999ZM18 19.9999H6V21.9999H18V19.9999ZM20 17.9999C20 19.1045 19.1046 19.9999 18 19.9999V21.9999C20.2091 21.9999 22 20.2091 22 17.9999H20ZM20 10.8074V17.9999H22V10.8074H20ZM19.2691 9.26159C19.7318 9.64148 20 10.2087 20 10.8074H22C22 9.61 21.4636 8.47559 20.5381 7.71581L19.2691 9.26159ZM13.9036 4.85661L19.2691 9.26159L20.5381 7.71581L15.1727 3.31083L13.9036 4.85661ZM10.0964 4.85661C11.2029 3.94819 12.7971 3.94819 13.9036 4.85661L15.1727 3.31083C13.3285 1.79678 10.6715 1.79678 8.82732 3.31083L10.0964 4.85661ZM4.73093 9.26159L10.0964 4.85661L8.82732 3.31083L3.46186 7.71581L4.73093 9.26159ZM13 12.9999H11V14.9999H13V12.9999ZM16 15.9999C16 14.343 14.6569 12.9999 13 12.9999V14.9999C13.5523 14.9999 14 15.4476 14 15.9999H16ZM16 18.9999V15.9999H14V18.9999H16ZM13 21.9999C14.6569 21.9999 16 20.6567 16 18.9999H14C14 19.5522 13.5523 19.9999 13 19.9999V21.9999ZM11 21.9999H13V19.9999H11V21.9999ZM8 18.9999C8 20.6567 9.34315 21.9999 11 21.9999V19.9999C10.4477 19.9999 10 19.5522 10 18.9999H8ZM8 15.9999V18.9999H10V15.9999H8ZM11 12.9999C9.34315 12.9999 8 14.343 8 15.9999H10C10 15.4476 10.4477 14.9999 11 14.9999V12.9999Z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default Icon
