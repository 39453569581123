import React from 'react'
import styled from 'styled-components'
import { Price } from '@pancakeswap/sdk'
import { Icon } from 'components/Icon'
import { StyledBalanceMaxMini } from './styleds'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

const TextCustom = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  color: #989898;
  justify-content: 'center';
  align-items: 'center';
`

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `${price?.quoteCurrency?.symbol} per ${price?.baseCurrency?.symbol}`
    : `${price?.baseCurrency?.symbol} per ${price?.quoteCurrency?.symbol}`

  return (
    <TextCustom>
      {show ? (
        <>
          {formattedPrice ?? '-'} {label}
          <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            <Icon name="swap" size="16px" />
          </StyledBalanceMaxMini>
        </>
      ) : (
        '-'
      )}
    </TextCustom>
  )
}
