import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" height="16px" width="16px" {...props} fill="none">
      <path d="M2.6665 8H13.3332" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" />
      <path d="M8 2.66675L8 13.3334" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" />
    </Svg>
  )
}

export default Icon
