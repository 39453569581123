import React from 'react'
import Svg from 'uikit/components/Svg/Svg'
import { SvgProps } from 'uikit/components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="133" height="114" viewBox="0 0 133 114" fill="none" {...props}>
      <g opacity="0.2">
        <path
          d="M56.4824 16.4431C60.7183 9.12496 71.284 9.12496 75.5218 16.4431L115.512 85.5013C120.806 94.6437 111.424 105.265 101.698 101.139L70.3073 87.8226C67.5615 86.6578 64.4606 86.6578 61.7152 87.8226L30.3279 101.139C20.6033 105.265 11.2184 94.6437 16.5102 85.5013L56.4824 16.4431Z"
          fill="#FD5E5A"
        />
        <path
          d="M51 53C51 44.7157 57.7157 38 66 38C74.2843 38 81 44.7157 81 53C81 61.2843 74.2843 68 66 68C57.7157 68 51 61.2843 51 53Z"
          stroke="#DE0500"
          strokeWidth="2"
          fill="none"
        />
        <path d="M61 48L71 58M71 48L61 58" stroke="#DE0500" strokeWidth="2" strokeLinecap="round" />
      </g>
    </Svg>
  )
}

export default Icon
