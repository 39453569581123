import React from 'react'
import styled from 'styled-components'
import { Text, Button } from 'uikit'
// import { Element } from 'react-scroll'

import image from './image.svg'

const Wrapper = styled.div`
  display: flex;
  margin-top: 32px;
`

const TextWrap = styled.div`
  padding-top: 80px;
  padding-bottom: 64px;
  max-width: 544px;
`

const ImgWrap = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Img = styled.img`
  width: 100%;
  max-width: 384px;
`

const TopBanner: React.FC = () => {
  return (
    <Wrapper>
      <TextWrap>
        {/* <Element name="addProject"> */}
        <Text mb="24px" fontSize="32px" color="contrast" bold maxWidth="314px">
          Launch Your Project on <span style={{ color: '#FD5E5A' }}>Polepup</span> Now!
        </Text>
        {/* </Element> */}
        <Text fontSize="16px" lineHeight="24px" color="textDisabled" mb="16px">
          Farms stimulate users to provide liquidity for your trading pair by distributing POLE tokens to your pair’s LP
          token holders. Launchpool is a platform where a project owner can distribute tokens to BSC users who stake
          POLE tokens in the pool. When a project applies for Polepup Launchpool we can also create a new farming pair
          (subject to discussion).
        </Text>
        <Text fontSize="16px" lineHeight="24px" color="textDisabled" mb="24px">
          Polepup Launchpool and Farms are platforms that help project teams promote their token and get exposure to
          thousands of active Biswap users across the globe. We look for strong teams with clear and innovative vision
          in the crypto space. If you think you are one of the projects, do not wait any longer and apply below.{' '}
        </Text>
        <Button variant="primary" width="412px">
          Apply to Launch
        </Button>
      </TextWrap>
      <ImgWrap>
        <Img src={image} />
      </ImgWrap>
    </Wrapper>
  )
}

export default TopBanner
