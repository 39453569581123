import React, { useState } from 'react'
import cn from 'classnames'
import { Pool } from 'state/types'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { Button } from 'uikit'
import pic from './img/logo.svg'
import picMini from './img/logoMini.svg'
import percent from './img/percent.svg'
import arrow from './img/chevron-down.svg'
import externalLink from './img/externalLink.svg'
import fox from './img/fox.svg'
import './style.scss'

export const CustomCardHeader = () => (
  <>
    <div className="launchpools-card_label">Max 100</div>
    <div className="launchpools-card_header">
      <div className="launchpools-card_logo">
        <img width={80} height={80} src={pic} alt="Logo" />
        <div className="launchpools-card_sublogo">
          <img width={31} height={31} src={picMini} alt="Small logo" />
        </div>
      </div>
      <div>
        <p className="launchpools-card_title">Auto Compound</p>
        <p className="launchpools-card_descr">Stake POLE — Earn POLE</p>
        <p className="launchpools-card_percent">
          <span>APR</span> 34.36%
          <img width={20} height={20} src={percent} alt="Percente" />
        </p>
      </div>
    </div>
  </>
)

export const AvaCard: React.FC<{ pool: Pool; account: string }> = ({ pool, account }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleIsOpen = () => setIsOpen((prevState) => !prevState)
  return (
    <div className="launchpools-card">
      <CustomCardHeader />

      {account ? (
        <Button type="button" variant="primary" className="launchpools-card_btn">
          Approve POLE
        </Button>
      ) : (
        <ConnectWalletButton width="100%" />
      )}

      {account && (
        <>
          <div className="launchpools-card_info available">
            <p className="launchpools-card_info_title">Recent POLE profit</p>
            <p className="launchpools-card_info_note">0.1% unstaking fee if withdrawn within 72h</p>
          </div>
          <div className="launchpools-card_info earned">
            <div>
              <p className="launchpools-card_info_earned">Earned</p>
              <div className="launchpools-card_info_label">Not active</div>
            </div>
            <Button type="button" variant="primary" minWidth="136px" disabled>
              Harvest
            </Button>
          </div>
        </>
      )}
      <button type="button" className="launchpools-card_toggler" onClick={toggleIsOpen}>
        <span>{isOpen ? 'Hide' : 'Details'}</span>
        <span className={cn('launchpools-card_toggler_arrow', { open: isOpen })}>
          <img width={12} height={12} src={arrow} alt="Chevron down" />
        </span>
      </button>
      {isOpen && (
        <div className="launchpools-card_content">
          <div className="launchpools-card_list">
            <p className="launchpools-card_list_item">
              <span className="launchpools-card_list_name">Total stake</span>
              <span className="launchpools-card_list_value total">34 564 345.234</span>
            </p>
            <p className="launchpools-card_list_item">
              <span className="launchpools-card_list_name">Performance Fee</span>
              <span className="launchpools-card_list_value">2.99%</span>
            </p>
          </div>
          <div className="launchpools-card_links">
            <a className="launchpools-card_link" href="/">
              View on BscScan <img width={16} height={16} src={externalLink} alt="BscScan" />
            </a>
            <a className="launchpools-card_link" href="/">
              <img width={16} height={16} src={fox} alt="Fox" />
              Add to Metamask
            </a>
          </div>
        </div>
      )}
    </div>
  )
}
