import React from 'react'
import styled from 'styled-components'

import PanelBody from './PanelBody'
// import PanelFooter from './PanelFooter'

import { SIDEBAR_WIDTH_REDUCED, SIDEBAR_WIDTH_FULL } from '../config'
import { PanelProps, PushedProps } from '../types'

interface Props extends PanelProps, PushedProps {
  showMenu: boolean
  isMobile: boolean
  togglePush: () => void
}

const StyledPanel = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  position: absolute;
  padding: ${({ isPushed }) => `${isPushed ? '32px 8px 8px' : '32px 20px 8px'}`};

  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  max-height: 780px;
  min-height: 500px;
  height: 70vh;
  transition: padding 0.2s, width 0.2s;
  z-index: 11;

  background: #ffffff;
  box-shadow: 0px 4px 38px rgba(96, 16, 30, 0.07);
  border-radius: 16px;
  border: none;

  width: ${({ isPushed }) => `${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px`};
`

const Panel: React.FC<Props> = (props) => {
  const { isPushed, showMenu, togglePush } = props
  return (
    <StyledPanel isPushed={isPushed} showMenu={showMenu}>
      <PanelBody {...props} isPushed={isPushed} togglePush={togglePush} />
      {/* <PanelFooter {...props} /> */}
    </StyledPanel>
  )
}

export default Panel
