import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 45 40" {...props}>
      <path fill="url(#a)" d="M21.15 8.64c1.9-3.06 4.61-4.14 5.73-4.3h1.33l2.1.47 1.53 2.1-2.1 6.5-8.6-4.77Z" />
      <path fill="url(#b)" d="M24.12 31.37c-1.91 3.05-4.62 4.13-5.73 4.3h-1.34l-2.1-.48-1.53-2.1 2.1-6.5 8.6 4.78Z" />
      <path
        fill="#D9D9D9"
        d="M33.08 37c12.76-13.37 10.03-23.69 4.88-26.26 3.66 4.66-.32 12.06-2.77 15.18l2.1 2-9.27 1.73-.38-8.89 2.96 1.82c10.5-12.9 1.43-19.96-3.9-18.24 9.07 0-1 14.42-7.36 21.58L33.08 37Z"
      />
      <path
        fill="url(#c)"
        d="M33.08 37c12.76-13.37 10.03-23.69 4.88-26.26 3.66 4.66-.32 12.06-2.77 15.18l2.1 2-9.27 1.73-.38-8.89 2.96 1.82c10.5-12.9 1.43-19.96-3.9-18.24 9.07 0-1 14.42-7.36 21.58L33.08 37Z"
      />
      <path
        fill="#D9D9D9"
        d="M12.18 3C-.58 16.37 2.15 26.69 7.3 29.26c-3.67-4.66.32-12.06 2.77-15.18l-2.1-2 9.26-1.73.38 8.89-2.96-1.82c-10.5 12.9-1.43 19.96 3.92 18.24-9.08 0 .98-14.42 7.35-21.58L12.18 3Z"
      />
      <path
        fill="url(#d)"
        d="M12.18 3C-.58 16.37 2.15 26.69 7.3 29.26c-3.67-4.66.32-12.06 2.77-15.18l-2.1-2 9.26-1.73.38 8.89-2.96-1.82c-10.5 12.9-1.43 19.96 3.92 18.24-9.08 0 .98-14.42 7.35-21.58L12.18 3Z"
      />
      <defs>
        <linearGradient id="a" x1="33.08" x2="6.47" y1="-36.06" y2="-9.63" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC7449" />
          <stop offset=".85" stopColor="#FC2B51" />
          <stop offset="1" stopColor="#CD2846" />
        </linearGradient>
        <linearGradient id="b" x1="12.18" x2="38.79" y1="76.06" y2="49.63" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC7449" />
          <stop offset=".85" stopColor="#FC2B51" />
          <stop offset="1" stopColor="#CD2846" />
        </linearGradient>
        <linearGradient id="c" x1="30.03" x2="16.28" y1=".13" y2="7.95" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC7449" />
          <stop offset=".47" stopColor="#FD4869" />
          <stop offset="1" stopColor="#FC2B51" />
        </linearGradient>
        <linearGradient id="d" x1="4.07" x2="27.92" y1="40.05" y2="37.94" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC7449" />
          <stop offset=".47" stopColor="#FD4869" />
          <stop offset="1" stopColor="#FC2B51" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Icon
