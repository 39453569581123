import styled from 'styled-components'
import Image from './Image'

const TokenImage = styled(Image)`
  box-shadow: 0 0 0 2px #fff;
  border-radius: 50%;
`
// &:before {
//   border-radius: 50%;
//   border: 2px solid #fff;
//   content: '';
//   height: 100%;
//   left: 0;
//   position: absolute;
//   top: 0;
//   width: 100%;
//   z-index: 7;
// }

export default TokenImage
