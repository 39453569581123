export const REFERRAL_GET_USER = 'referral/REFERRAL_GET_USER'
export const REFERRAL_GET_USERS = 'referral/REFERRAL_GET_USERS'
export const REFERRAL_CREATE_LINK = 'referral/REFERRAL_CREATE_LINK'
export const REFERRAL_GET_LINKS = 'referral/REFERRAL_GET_LINKS'
export const REFERRAL_REGISTER_USER = 'referral/REFERRAL_REGISTER_USER'
export const REFERRAL_FETCH_FAILED = 'referral/REFERRAL_FETCH_FAILED'
export const REFERRAL_CHANGE_LINK_NOTE = 'referral/REFERRAL_CHANGE_LINK_NOTE'
export const REFERRAL_LOADING_STOP = 'referral/REFERRAL_LOADING_STOP'
export const REFERRAL_LOADING_START = 'referral/REFERRAL_LOADING_START'
export const SET_DEFAULT_LINK = 'referral/SET_DEFAULT_LINK'
export const FAILED = 'FAILED'
export const FETCH_REFERRALS_TABLE = 'referral/FETCH_REFERRALS_TABLE'
export const FETCH_FARMS_TABLE = 'referral/FETCH_FARMS_TABLE'
export const FETCH_POOL_TABLE = 'referral/FETCH_POOL_TABLE'
export const FETCH_LOTTERY_TABLE = 'referral/FETCH_LOTTERY_TABLE'
export const FETCH_SWAP_TABLE = 'referral/FETCH_SWAP_TABLE'
export const GET_WITHDRAW_HISTORY = 'referral/GET_WITHDRAW_HISTORY'
export const SEND_WITHDRAW_DATA = 'SEND_WITHDRAW_DATA'
export const CLEAR_HISTORY_DATA = 'CLEAR_HISTORY_DATA'
export const REF_LOGOUT = 'REF_LOGOUT'
export const GET_HELPER_DATA = 'GET_HELPER_DATA'
export const USER_REGISTERED = 'USER_REGISTERED'
