import React from 'react'
import styled from 'styled-components'
import { Icon } from 'components/Icon'
import Link from './Link'
import { LinkProps } from './types'

const StyledLink = styled(Link)`
  display: grid;
  grid-template-columns: 1fr 20px;
  column-gap: 4px;
  align-items: 'center';
`

const LinkExternal: React.FC<LinkProps> = ({ children, ...props }) => {
  return (
    <StyledLink external {...props}>
      {children}
      <Icon name="external_link" size={20} />
    </StyledLink>
  )
}

export default LinkExternal
