import React, { FC } from 'react'

import './styles.scss'

export const NoteTextarea: FC<any> = ({ value, onChange }) => {
  return (
    <textarea
      name="note"
      value={value}
      onChange={onChange}
      className="link-popup_note edit textarea--edit"
      placeholder="Note..."
    />
  )
}
