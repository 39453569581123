import React from 'react'
import { NavLink } from 'react-router-dom'

const MenuLink: React.FC<any> = ({ href, isDisabled, ...otherProps }) => {
  const isHttpLink = href?.startsWith('http')

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Tag: any = isDisabled ? 'span' : isHttpLink ? 'a' : NavLink
  const props = isDisabled ? {} : isHttpLink ? { href } : { to: href }
  return <Tag {...props} {...otherProps} />
}

export default MenuLink
