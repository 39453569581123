import React from 'react'
import { GradientOutlineButton } from 'uikit'
import Text from '../../components/Text/Text'
import LinkExternal from '../../components/Link/LinkExternal'
import Flex from '../../components/Box/Flex'
import { Modal } from '../Modal'
import CopyToClipboard from './CopyToClipboard'
import { connectorLocalStorageKey } from './config'

interface Props {
  account: string
  logout: () => void
  onDismiss?: () => void
  t: (key: string) => string
}

const AccountModal: React.FC<Props> = ({ account, logout, onDismiss = () => null, t }) => (
  <Modal minWidth="520px" bodyPadding="18px 22px 22px" title={t('Your wallet')} onDismiss={onDismiss}>
    <Text
      fontSize="16px"
      bold
      style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginBottom: '16px' }}
    >
      {account}
    </Text>
    <Flex mb="32px">
      <CopyToClipboard toCopy={account}>{t('Copy Address')}</CopyToClipboard>
      <LinkExternal small href={`https://avascan.info/blockchain/c/address/${account}`} ml="32px">
        View on AvaScan
      </LinkExternal>
    </Flex>
    <Flex justifyContent="center">
      <GradientOutlineButton
        width="100%"
        onClick={() => {
          logout()
          window.localStorage.removeItem(connectorLocalStorageKey)
          onDismiss()
        }}
      >
        {t('Logout')}
      </GradientOutlineButton>
    </Flex>
  </Modal>
)

export default AccountModal
