import React from 'react'
import Svg from 'uikit/components/Svg/Svg'
import { SvgProps } from 'uikit/components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 384 411" width="384" height="411" fill="none" {...props}>
      <g filter="url(#filter0_f)">
        <rect
          x="143.479"
          y="142"
          width="144.957"
          height="144.957"
          rx="72.4784"
          transform="rotate(30 143.479 142)"
          fill="url(#paint0_linear)"
        />
        <path
          d="M173.919 218.808C179.855 215.523 187.153 219.736 187.276 226.519L187.329 229.451C187.373 231.888 188.404 234.202 190.185 235.866L192.328 237.867C197.287 242.497 195.535 250.74 189.122 252.953L186.35 253.91C184.046 254.705 182.163 256.4 181.132 258.608L179.89 261.265C177.019 267.411 168.639 268.292 164.552 262.877L162.786 260.536C161.318 258.591 159.123 257.324 156.705 257.025L153.794 256.666C147.061 255.835 143.634 248.136 147.521 242.577L149.202 240.173C150.598 238.176 151.125 235.698 150.661 233.305L150.104 230.426C148.814 223.766 155.076 218.127 161.565 220.106L164.37 220.962C166.701 221.673 169.221 221.408 171.353 220.228L173.919 218.808Z"
          fill="white"
          stroke="white"
          strokeWidth="2"
        />
      </g>
      <rect
        x="153.766"
        y="12"
        width="265.531"
        height="265.531"
        rx="132.765"
        transform="rotate(30 153.766 12)"
        fill="url(#paint1_linear)"
      />
      <path
        d="M220.411 146.674C226.347 143.39 233.644 147.603 233.768 154.386L234.148 175.365C234.193 177.802 235.223 180.116 237.004 181.78L252.34 196.101C257.299 200.731 255.547 208.973 249.134 211.186L229.299 218.032C226.995 218.827 225.112 220.522 224.081 222.73L215.2 241.741C212.328 247.887 203.948 248.768 199.862 243.353L187.222 226.604C185.754 224.659 183.56 223.392 181.141 223.093L160.316 220.521C153.584 219.69 150.156 211.992 154.043 206.432L166.067 189.236C167.463 187.238 167.99 184.76 167.527 182.367L163.537 161.767C162.247 155.107 168.51 149.468 174.998 151.447L195.069 157.568C197.4 158.279 199.92 158.014 202.052 156.834L220.411 146.674Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
      />
      <defs>
        <filter
          id="filter0_f"
          x="0.517578"
          y="71.5166"
          width="338.981"
          height="338.981"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="48.5" result="effect1_foregroundBlur" />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="315.131"
          y1="243.001"
          x2="223.144"
          y2="115.207"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC7449" />
          <stop offset="0.471875" stopColor="#FD4869" />
          <stop offset="1" stopColor="#FC2B51" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="468.197"
          y1="197.012"
          x2="299.696"
          y2="-37.079"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC7449" />
          <stop offset="0.471875" stopColor="#FD4869" />
          <stop offset="1" stopColor="#FC2B51" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Icon
