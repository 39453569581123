import React, { FC } from 'react'
import styled from 'styled-components'

import { Text, Link } from 'uikit'

const Wrap = styled.div<{ isAbout?: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: ${({ isAbout }) => (isAbout ? '0' : '155px')};
  column-gap: 20px;
`

const Item = styled.div`
  display: grid;
  padding: 0 20px 0 28px;
`

type InfoInstruction = {
  isAbout?: boolean
}

const InfoInstruction: FC<InfoInstruction> = ({ isAbout }) => {
  const data = [
    {
      id: 1,
      title: 'Farms Referral Rewards',
      text: 'Gain 5% from your friends earnings on Farms! Your rewards will be displayed on the referral balance at the moment your invited friends withdraw their earned POLE tokens.',
      // link: 'more',
    },
    {
      id: 2,
      title: 'Launchpools Referral Rewards',
      text: 'Get 5% of from friends’ profit obtained in Launchpools! The reward is only valid for the pool in which POLE is staked in return for more POLE.',
      // link: 'more',
    },
    {
      id: 3,
      title: 'Swaps Referral Rewards',
      text: 'Get a certain commission reward each time your friend makes a swap! Receive your reward immediately after the swap is made. Swaps referral program will be active for certain pairs only.',
      // link: 'more',
    },
  ]

  return (
    <Wrap isAbout={isAbout}>
      {data.map((i) => (
        <Item key={i.id}>
          <div>
            <Text fontSize="18px" color="#343434" fontWeight="600">
              {i.title}
            </Text>
            <Text fontSize="16px" color="#343434" fontWeight="500" mt={16} mb={24} max->
              {i.text}
            </Text>
          </div>
          <Link
            href="/#" // !TODO
            color="primary"
            target="_blank"
            className="infoInstruction-block__link"
          >
            Read more
          </Link>
        </Item>
      ))}
    </Wrap>
  )
}

export default InfoInstruction
