import React from 'react'
import styled from 'styled-components'
import { Icon } from 'components/Icon'
import Flex from '../../components/Box/Flex'
import { Box } from '../../components/Box'
import { ArrowBackIcon } from '../../components/Svg'
import { IconButton } from '../../components/Button'
import { ModalProps } from './types'

export const ModalHeader = styled.div<{ background?: string }>`
  padding: 22px;
  font-weight: 600;
  font-size: 24px;
  color: #343434;
  position: relative;
`

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
`

export const ModalBody = styled(Flex)`
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
`

const CloseIconButton = styled(IconButton)`
  position: absolute;
  right: 22px;
  top: 22px;
  color: #343434;
  width: 24px;
  height: 24px;
  z-index: 2;
`

export const ModalCloseButton: React.FC<{ onDismiss: ModalProps['onDismiss'] }> = ({ onDismiss }) => {
  return (
    <CloseIconButton variant="text" onClick={onDismiss} aria-label="Close the dialog">
      <Icon name="cross" />
    </CloseIconButton>
  )
}

export const ModalBackButton: React.FC<{ onBack: ModalProps['onBack'] }> = ({ onBack }) => {
  return (
    <IconButton variant="text" onClick={onBack} area-label="go back" mr="8px">
      <ArrowBackIcon color="primary" />
    </IconButton>
  )
}

export const ModalContainer = styled(Box)<{ minWidth: string }>`
  overflow: hidden;
  background: white;
  border: none;
  border-radius: 20px;
  min-width: ${({ minWidth }) => minWidth};
  max-width: 100%;
  max-height: 100vh;
  z-index: ${({ theme }) => theme.zIndices.modal};
`
