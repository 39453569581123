import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
    isDisabled: true,
  },
  {
    label: 'Exchange',
    icon: 'SwapIcon',
    href: '/swap',
    isDisabled: false,
  },
  {
    label: t('Liquidity'),
    icon: 'LiquidityIcon',
    href: '/liquidity',
    isDisabled: false,
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
    // isDisabled: true,
  },
  {
    label: 'Launchpools',
    icon: 'LaunchpoolIcon',
    href: '/pools',
    isDisabled: false,
  },
  {
    label: 'Referral program',
    icon: 'ReferralProgramIcon',
    href: '/referral',
    isDisabled: false,
  },
]

export default config
