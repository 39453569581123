import React, { FC } from 'react'
import styled from 'styled-components'

import { MyInviterIcon } from 'views/Referral/Icons'
import { H1 } from 'components/Text'
import InviteInstruction from './components/InviteInstruction'
import InfoInstruction from './components/InfoInstruction'

const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 51px;
  align-items: center;
  margin-bottom: 20px;
`

type Props = {
  isAbout?: boolean
}

const NotRegisterPage: FC<Props> = ({ isAbout }): JSX.Element => {
  return (
    <>
      <Header>
        <H1>How to invite friends</H1>
        {!isAbout && <MyInviterIcon />}
      </Header>
      <InviteInstruction />
      <InfoInstruction isAbout={isAbout} />
    </>
  )
}

export default NotRegisterPage
