import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M4 12H20" stroke="#6C6C6C" strokeWidth="2" strokeLinecap="round" />
      <path d="M12 4L12 20" stroke="#6C6C6C" strokeWidth="2" strokeLinecap="round" />
    </Svg>
  )
}

export default Icon
