export const gradient = 'lineargradient(262.79deg, #FC7449 2.72%, #FD4869 46.62%, #FC2B51 95.76%)'
export const primary = '#FD5E5A'
export const orange = '#FC7449'
export const yellow = '#FFC14A'
export const violet = '#B13ADB'
export const green = '#1DC872'
export const neutral100 = '#F7F7F7'
export const neutral200 = '#FFF6F7'
export const neutral300 = '#FFEFEF'
export const neutral400 = '#E5DCDC'
export const neutral500 = '#CDCDCD'
export const neutral600 = '#BDBDBD'
export const neutral700 = '#989898'
export const neutral900 = '#343434'
export const gray = '#585858'
export const pink = '#FFE7E7'
export const white = '#FFFFFF'
