import React, { FC } from 'react'
import styled from 'styled-components'

import { NotRegisteredHeroIcon } from 'views/Referral/Icons'

import { HeroWrap } from 'views/Referral/HeroWrap'

const IconWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

const NotRegisterHero: FC = (): JSX.Element => (
  <HeroWrap>
    <IconWrap>
      <NotRegisteredHeroIcon />
    </IconWrap>
  </HeroWrap>
)

export default NotRegisterHero
