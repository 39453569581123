import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 132 32" {...props}>
      <path
        fill="#fff"
        d="M51.46 19.85h-2.49V25h-3.34V9.2h5.83c3.85 0 6.25 1.83 6.25 5.26 0 3.43-2.4 5.4-6.25 5.4Zm-.02-7.83h-2.47v5.02h2.47c1.89 0 2.94-1 2.94-2.56s-1.05-2.46-2.94-2.46Zm13.45 13.2a5.93 5.93 0 0 1-5.96-6.1 5.91 5.91 0 0 1 5.96-6.09 5.92 5.92 0 0 1 5.96 6.1c0 3.4-2.55 6.09-5.96 6.09Zm0-2.7c1.52 0 2.82-1.22 2.82-3.4 0-2.17-1.3-3.36-2.82-3.36-1.52 0-2.81 1.19-2.81 3.37 0 2.17 1.3 3.38 2.81 3.38ZM76.1 25H73V9.2h3.08V25Zm8.04.2c-3.52 0-5.87-2.44-5.87-6.12 0-3.5 2.44-6.05 5.83-6.05 3.72 0 6.18 3.02 5.63 6.84h-8.34c.2 1.92 1.12 2.97 2.69 2.97 1.34 0 2.24-.66 2.6-1.82h3.03c-.66 2.66-2.7 4.18-5.57 4.18Zm-.1-9.92c-1.44 0-2.34.92-2.6 2.66h5.06c-.1-1.63-1.02-2.66-2.47-2.66Zm19.66 3.85c0 3.47-2.04 6.09-5.2 6.09-1.4 0-2.58-.6-3.46-1.74v5.77h-3.06v-16h2.81v1.72a4.44 4.44 0 0 1 3.7-1.94c3.17 0 5.21 2.64 5.21 6.1Zm-3.14 0c0-2.2-1.26-3.4-2.75-3.4-1.5 0-2.75 1.17-2.75 3.4 0 2.22 1.25 3.36 2.75 3.36s2.75-1.12 2.75-3.36Zm12.45.92v-6.8h3.04V25h-2.8v-1.54a4.02 4.02 0 0 1-3.43 1.76c-2.35 0-4.07-1.43-4.07-4.49v-7.48h3.06v6.89c0 1.65.82 2.37 2.05 2.37 1.1 0 2.15-.88 2.15-2.46Zm17.71-.92c0 3.47-2.04 6.09-5.21 6.09-1.39 0-2.57-.6-3.45-1.74v5.77H119v-16h2.81v1.72a4.44 4.44 0 0 1 3.7-1.94c3.17 0 5.21 2.64 5.21 6.1Zm-3.14 0c0-2.2-1.26-3.4-2.75-3.4-1.5 0-2.75 1.17-2.75 3.4 0 2.22 1.25 3.36 2.75 3.36s2.75-1.12 2.75-3.36Z"
      />
      <rect width="32" height="32" fill="#fff" rx="4" />
      <path fill="url(#a)" d="M15.04 8.14a6.48 6.48 0 0 1 4.07-3.06h.95l1.5.34 1.08 1.5-1.5 4.62-6.1-3.4Z" />
      <path fill="url(#b)" d="M17.15 24.3a6.48 6.48 0 0 1-4.07 3.06h-.96l-1.49-.34-1.09-1.5 1.5-4.61 6.11 3.4Z" />
      <path
        fill="#D9D9D9"
        d="M23.53 28.31c9.07-9.5 7.13-16.84 3.46-18.68 2.6 3.32-.23 8.58-1.97 10.8l1.5 1.43-6.6 1.22-.26-6.31 2.1 1.29c7.47-9.17 1.02-14.2-2.78-12.98 6.45 0-.7 10.26-5.23 15.35l9.78 7.88Z"
      />
      <path
        fill="url(#c)"
        d="M23.53 28.31c9.07-9.5 7.13-16.84 3.46-18.68 2.6 3.32-.23 8.58-1.97 10.8l1.5 1.43-6.6 1.22-.26-6.31 2.1 1.29c7.47-9.17 1.02-14.2-2.78-12.98 6.45 0-.7 10.26-5.23 15.35l9.78 7.88Z"
      />
      <path
        fill="#D9D9D9"
        d="M8.66 4.13C-.4 13.64 1.53 20.98 5.2 22.81c-2.61-3.31.22-8.58 1.97-10.8l-1.5-1.42 6.59-1.23.27 6.32-2.1-1.3c-7.47 9.18-1.02 14.2 2.78 12.98-6.45 0 .7-10.25 5.23-15.35L8.66 4.13Z"
      />
      <path
        fill="url(#d)"
        d="M8.66 4.13C-.4 13.64 1.53 20.98 5.2 22.81c-2.61-3.31.22-8.58 1.97-10.8l-1.5-1.42 6.59-1.23.27 6.32-2.1-1.3c-7.47 9.18-1.02 14.2 2.78 12.98-6.45 0 .7-10.25 5.23-15.35L8.66 4.13Z"
      />
      <defs>
        <linearGradient id="a" x1="23.53" x2="4.6" y1="-23.64" y2="-4.85" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC7449" />
          <stop offset=".85" stopColor="#FC2B51" />
          <stop offset="1" stopColor="#CD2846" />
        </linearGradient>
        <linearGradient id="b" x1="8.66" x2="27.59" y1="56.09" y2="37.3" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC7449" />
          <stop offset=".85" stopColor="#FC2B51" />
          <stop offset="1" stopColor="#CD2846" />
        </linearGradient>
        <linearGradient id="c" x1="21.35" x2="11.57" y1="2.1" y2="7.66" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC7449" />
          <stop offset=".47" stopColor="#FD4869" />
          <stop offset="1" stopColor="#FC2B51" />
        </linearGradient>
        <linearGradient id="d" x1="2.89" x2="19.85" y1="30.48" y2="28.98" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC7449" />
          <stop offset=".47" stopColor="#FD4869" />
          <stop offset="1" stopColor="#FC2B51" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Icon
