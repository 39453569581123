import React from 'react'
import { Text } from 'uikit'
import styled from 'styled-components'

import stepOne from './step-one.png'
import stepTwo from './step-two.png'
import stepThree from './step-three.png'

const Wrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 64px;
  column-gap: 20px;
`

const Item = styled.div`
  background: #ffffff;
  border-radius: 16px;
  padding: 0 0 28px;
`

const TextWrap = styled.div`
  padding: 0 28px;
  display: grid;
  row-gap: 16px;
`

const ImageWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const InviteInstruction = () => {
  const data = [
    {
      id: 1,
      title: 'Get a referral link',
      image: stepOne,
      text: 'Connect a wallet and generate your referral link in the Referral section.',
    },
    {
      id: 2,
      title: 'Invite friends',
      image: stepTwo,
      text: 'Invite your friends to register via your referral link ',
    },
    {
      id: 3,
      title: 'Earn crypto',
      image: stepThree,
      text: 'Receive referral rewards in POLE tokens from your friends’ earnings & swaps',
    },
  ]
  return (
    <Wrap>
      {data.map((i) => (
        <Item key={i.id}>
          <ImageWrap>
            <img src={i.image} alt="Invite friend" />
          </ImageWrap>

          <TextWrap>
            <Text fontSize="16px" color="#343434" fontWeight="600">
              {i.title}
            </Text>
            <Text fontSize="16px" color="#7E7E7E">
              {i.text}
            </Text>
          </TextWrap>
        </Item>
      ))}
    </Wrap>
  )
}

export default InviteInstruction
