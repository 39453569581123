import React, { FC } from 'react'
import styled from 'styled-components'
import { Icon } from 'components/Icon'
import { DATA } from './constants'

const List = styled.ul<{ isFooter: boolean }>`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: ${({ isFooter }) => (isFooter ? '48px' : '24px')};
`

const Link = styled.a<any>`
  color: ${({ isFooter }) => (isFooter ? '#fd5263' : 'white')};
  background: ${({ isFooter }) =>
    isFooter ? 'white' : 'linear-gradient(262.79deg, #fc7449 2.72%, #fd4869 46.62%, #fc2b51 95.76%)'};
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: opacity 250ms;
  &:hover,
  &:focus {
    opacity: 0.6;
  }
`
type SocialProps = {
  isFooter?: boolean
}

export const Social: FC<SocialProps> = ({ isFooter }) => (
  <List isFooter={isFooter}>
    {DATA.map(({ name, link }) => (
      <li key={name}>
        <Link isFooter={isFooter} target="_blank" rel="noreferrer" href={link}>
          <Icon name={name} size={24} />
        </Link>
      </li>
    ))}
  </List>
)
