export const DATA = [
  {
    name: 'telegram',
    link: '#',
  },
  {
    name: 'medium',
    link: '#',
  },
  {
    name: 'twitter',
    link: '#',
  },
]
