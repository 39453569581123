import React from 'react'
import { useWalletModal } from 'uikit/widgets/WalletModal'
import { Login } from 'uikit/widgets/WalletModal/types'
import Button from 'uikit/components/Button/Button'
import { GradientOutlineButton } from 'uikit'
import { useTranslation } from 'contexts/Localization'

interface Props {
  account?: string
  login: Login
  logout: () => void
}

const UserBlock: React.FC<Props> = ({ account, login, logout }) => {
  const { t } = useTranslation()
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, t, account)
  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null
  return (
    <div>
      {account ? (
        <GradientOutlineButton
          onClick={() => {
            onPresentAccountModal()
          }}
          height="48px"
          background="#FFF6F7"
        >
          {accountEllipsis}
        </GradientOutlineButton>
      ) : (
        <Button
          scale="sm"
          onClick={() => {
            onPresentConnectModal()
          }}
          variant="primary"
          height="48px"
        >
          Connect wallet
        </Button>
      )}
    </div>
  )
}

export default React.memo(UserBlock, (prevProps, nextProps) => prevProps.account === nextProps.account)
