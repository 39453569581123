import { scales, variants } from './types'

export const scaleVariants = {
  [scales.LG]: {
    height: '60px',
    padding: '0 24px',
  },
  [scales.MD]: {
    height: '48px',
    padding: '0 24px',
  },
  [scales.SM]: {
    height: '32px',
    padding: '0 16px',
  },
  [scales.XS]: {
    height: '20px',
    fontSize: '12px',
    padding: '0 8px',
  },
}

export const styleVariants = {
  [variants.PRIMARY]: {
    background: 'linear-gradient(262.79deg, #FC7449 2.72%, #FD4869 46.62%, #FC2B51 95.76%)',
    boxShadow: 'none',
    minWidth: '220px',
    height: '60px',
    color: 'white',
    '&:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active)': {
      opacity: 1,
      boxShadow: '0px 8px 48px rgba(253, 82, 98, 0.4)',
    },
    ':disabled': {
      background: '#E5DCDC',
    },
  },
  [variants.SECONDARY]: {
    backgroundColor: 'transparent',
    border: '2px solid',
    borderColor: 'primary',
    boxShadow: 'none',
    color: 'primary',
    ':disabled': {
      backgroundColor: 'transparent',
    },
  },
  [variants.TERTIARY]: {
    backgroundColor: 'tertiary',
    boxShadow: 'none',
    color: '#343434',
    fontWeight: 500,
    fontSize: '18px',
    height: '46px',
    width: '64px',
    borderRadius: '16px',
    '&:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active)': {
      opacity: 1,
      backgroundColor: 'rgb(99%, 37%, 35%, 0.1)',
      color: '#343434',
    },
  },
  [variants.TERTIARY_ACTIVE]: {
    backgroundColor: '#FD5E5A',
    boxShadow: 'none',
    fontWeight: 500,
    fontSize: '18px',
    height: '46px',
    width: '64px',
    borderRadius: '16px',
    color: '#ffffff',
    '&:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active)': {
      opacity: 1,
      backgroundColor: '#FD5E5A',
      color: '#ffffff',
    },
  },
  [variants.SUBTLE]: {
    backgroundColor: '#FFFFFF',
    color: '#FD5E5A',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    borderRadius: '10px',
    height: '48px',
    '&:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active)': {
      opacity: 1,
    },
  },
  [variants.DANGER]: {
    backgroundColor: 'failure',
    color: 'white',
  },
  [variants.SUCCESS]: {
    backgroundColor: 'success',
    color: 'white',
  },
  [variants.TEXT]: {
    backgroundColor: 'transparent',
    color: 'primary',
    boxShadow: 'none',
    '&:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active)': {
      opacity: 1,
      color: '#B93936',
    },
    '&:active:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled)': {
      opacity: 1,
      color: '#B93936',
    },
  },
  [variants.LIGHT]: {
    backgroundColor: 'input',
    color: 'textSubtle',
    boxShadow: 'none',
  },
  [variants.OUTLINE]: {
    backgroundColor: '#ffffff',
    width: '100%',
    height: '100%',
    color: '#FD5E5A',
    boxShadow: 'none',
    borderRadius: '14px',
    '&:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active)': {
      opacity: 1,
      borderColor: 'transparent',
    },
  },
  [variants.METAMASK]: {
    backgroundColor: 'transparent',
    border: '2px solid rgba(219, 219, 219, 0.4)',
    borderHeight: '60px',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '22px',
    color: '#343434',
    boxShadow: 'none',
    justifyContent: 'left',
    padding: '14px 16px',
    height: '60px',
    transition: 'all 200ms',
    '&:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active)': {
      opacity: 1,
      borderColor: '#FD5E5A',
    },
  },
  [variants.LINK]: {
    backgroundColor: 'transparent',
    color: '#FD5E5A',
    boxShadow: 'none',
    // fontFamily: 'General Sans',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    height: '24px',
    padding: 0,
  },
  [variants.TAB]: {
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#FD5E5A',
    boxShadow: 'none',
    height: '48px',
    padding: '13px',
    minWidth: '116px',
  },
}
