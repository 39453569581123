import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <circle cx="6" cy="17" r="4" stroke="currentColor" fill="none" strokeWidth="2" />
      <circle cx="21" cy="19" r="2.25" stroke="currentColor" fill="none" strokeWidth="1.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.16159 8.94189C7.6225 8.86651 7.05446 9.1425 7.01221 9.68519C7.00412 9.78906 7 9.89405 7 10C7 10.0407 7.03013 10.0751 7.07033 10.0813C10.4283 10.5965 13 13.4979 13 17C13 17.5295 13.4029 18 13.9325 18H14.7847C15.55 18 16.1852 17.4444 16.5284 16.7604C17.3499 15.1235 19.0437 14 21 14C21.746 14 22.4539 14.1634 23.0898 14.4563C23.4932 14.6422 24 14.4442 24 14V10C24 7.79086 22.2091 6 20 6H14.7741C14.4171 6 14.0925 6.19423 13.8849 6.48466C12.7968 8.00741 11.0143 9 9 9C8.71547 9 8.43556 8.98019 8.16159 8.94189Z"
        fill="currentColor"
      />
      <rect x="14.2427" y="2" width="1" height="6" rx="0.5" transform="rotate(45 14.2427 2)" fill="currentColor" />
      <path
        d="M5 7C5.31476 7 5.61115 7.14819 5.8 7.4C6.29443 8.05924 5.82405 9 5 9L3 9C2.44772 9 2 8.55228 2 8C2 7.44772 2.44772 7 3 7L5 7Z"
        fill="currentColor"
      />
      <rect x="12" y="4.70703" width="1" height="6" rx="0.5" transform="rotate(-45 12 4.70703)" fill="currentColor" />
    </Svg>
  )
}

export default Icon
