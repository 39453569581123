// types
import {
  REFERRAL_GET_LINKS,
  REFERRAL_CREATE_LINK,
  REFERRAL_GET_USER,
  REFERRAL_GET_USERS,
  REFERRAL_REGISTER_USER,
  REFERRAL_CHANGE_LINK_NOTE,
  REFERRAL_FETCH_FAILED,
  REFERRAL_LOADING_STOP,
  REFERRAL_LOADING_START,
  FETCH_REFERRALS_TABLE,
  FETCH_FARMS_TABLE,
  FETCH_SWAP_TABLE,
  FETCH_POOL_TABLE,
  SET_DEFAULT_LINK,
  GET_WITHDRAW_HISTORY,
  GET_HELPER_DATA,
  SEND_WITHDRAW_DATA,
  FAILED,
  CLEAR_HISTORY_DATA,
  REF_LOGOUT,
  FETCH_LOTTERY_TABLE,
  USER_REGISTERED,
} from './actionTypes'

import { CLEAR_ERROR, SEND_HARVEST_DATA } from '../freebsw/actionTypes'

// state
const initialState: any = {
  referralUser: null,
  referralInviter: null,
  referralLinks: [],
  referralUsers: null,
  createdLink: null,
  defaultReferralLink: {},
  totalFriends: 0,
  totalEarned: 0,
  isLoading: true,
  referralsTable: [],
  swapTable: [],
  farmsTable: [],
  poolTable: [],
  lotteryTable: [],
  withdrawHistory: null,
  helperData: null,
  refError: null,
  isRegistered: null,
}

export default function referralReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case REF_LOGOUT:
      return {
        ...state,
        referralUser: null,
        isRegistered: null,
      }
    case CLEAR_HISTORY_DATA:
      return {
        ...state,
        withdrawHistory: null,
      }
    case SEND_WITHDRAW_DATA:
      return {
        ...state,
        referralUser: {
          ...state.referralUser,
          balanceDistribution: payload.balanceDistribution,
          balanceSwap: payload.balanceSwap,
          balancePool: payload.balancePool,
          balanceFarm: payload.balanceFarm,
          balanceLottery: payload.balanceLottery,
        },
      }
    case GET_HELPER_DATA:
      return {
        ...state,
        helperData: payload,
      }
    case SEND_HARVEST_DATA:
      return {
        ...state,
        referralUser: {
          ...state.referralUser,
          lastDistributionAt: payload.lastDistributionAt,
          balanceDistribution: payload.balanceDistribution,
          statsEarnedDistribution: payload.statsEarnedDistribution,
          balanceLottery: payload.balanceLottery,
        },
      }
    case GET_WITHDRAW_HISTORY:
      return {
        ...state,
        withdrawHistory: payload,
      }
    case SET_DEFAULT_LINK:
      return {
        ...state,
        defaultReferralLink: payload,
      }
    case FETCH_POOL_TABLE:
      return {
        ...state,
        poolTable: payload,
      }
    case FETCH_LOTTERY_TABLE:
      return {
        ...state,
        lotteryTable: payload,
      }
    case FETCH_FARMS_TABLE:
      return {
        ...state,
        farmsTable: payload,
      }
    case FETCH_SWAP_TABLE:
      return {
        ...state,
        swapTable: payload,
      }
    case FETCH_REFERRALS_TABLE:
      return {
        ...state,
        referralsTable: payload,
      }
    case REFERRAL_LOADING_START:
      return {
        ...state,
        isLoading: true,
      }
    case USER_REGISTERED:
      return {
        ...state,
        isRegistered: payload,
      }
    case REFERRAL_GET_USER:
      return {
        ...state,
        referralUser: payload.user,
        referralInviter: payload.inviter,
        // totalFriends:
        //   payload.user &&
        //   // Number(payload.user.statsCountSwap) +
        //   Number(payload.user.statsUsersFarm) + Number(payload.user.statsUsersPool),
        totalEarned:
          payload.user &&
          // Number(payload.user.statsEarnedSwap) +
          Number(payload.user.statsEarnedFarm) + Number(payload.user.statsEarnedPool),
      }
    case REFERRAL_GET_LINKS:
      return {
        ...state,
        referralLinks: payload,
        defaultReferralLink:
          payload.oldData && payload.oldData.find((el) => el._id === state.referralUser.defaultReferralLink),
      }
    case REFERRAL_CREATE_LINK:
      // eslint-disable-next-line no-case-declarations
      // const localRefLinkData = [...state.referralLinks.oldData, payload.data]
      return {
        ...state,
        createdLink: payload,
        referralLinks: {
          oldData: [...state.referralLinks.oldData, payload.data],
          page: state.referralLinks.page,
          pages: state.referralLinks.pages,
        },
        defaultReferralLink: payload.checked ? payload.data : state.defaultReferralLink,
      }
    case REFERRAL_GET_USERS:
      return {
        ...state,
        referralUsers: payload,
      }
    case REFERRAL_REGISTER_USER:
      return {
        ...state,
        referralUser: payload,
      }
    case REFERRAL_CHANGE_LINK_NOTE:
      return {
        ...state,
        defaultReferralLink:
          state.defaultReferralLink._id === payload.id
            ? {
                ...state.defaultReferralLink,
                note: payload.note,
              }
            : state.defaultReferralLink,
        referralLinks: {
          oldData: state.referralLinks.oldData.map((link) =>
            link._id === payload.id
              ? {
                  ...link,
                  note: payload.note,
                }
              : link,
          ),
        },
        page: state.referralLinks.page,
        pages: state.referralLinks.pages,
      }
    case REFERRAL_LOADING_STOP:
      return {
        ...state,
        isLoading: false,
      }
    case CLEAR_ERROR:
      return {
        ...state,
        refError: '',
      }
    case FAILED:
      return {
        ...state,
        refError: payload,
      }
    case REFERRAL_FETCH_FAILED:
      return {
        ...state,
      }
    default:
      return state
  }
}
