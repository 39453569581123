import styled from 'styled-components'

export const H1 = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: 0.01em;
  color: #343434;
`
export const P = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.37;
  color: #343434;
`
