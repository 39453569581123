import { CLEAR_ERROR, ERROR, GET_DISTRIBUTION_DATA, GET_DISTRIBUTION_TABLE, SEND_HARVEST_DATA } from './actionTypes'
// eslint-disable-next-line import/named

const INITIAL_STATE: any = {
  distribution: null,
  tableData: null,
  harvestData: null,
  error: '',
}
// balanceDistribution: "999999519833333333333333"
// lastDistributionAt: "2021-05-14T20:54:53.683Z"
// statsEarnedDistibution: "41666666666666666"
export default function freebswReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action
  switch (type) {
    case GET_DISTRIBUTION_DATA:
      return {
        ...state,
        distribution: payload,
      }
    case GET_DISTRIBUTION_TABLE:
      return {
        ...state,
        tableData: payload,
      }
    case SEND_HARVEST_DATA:
      return {
        ...state,
        harvestData: payload,
      }
    case ERROR:
      return {
        ...state,
        error: payload,
      }
    case CLEAR_ERROR:
      return {
        ...state,
        error: '',
      }
    default:
      return state
  }
}
