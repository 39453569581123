import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M5.32664 19.471C4.59426 19.6541 3.93087 18.9907 4.11396 18.2584L4.69558 15.9319C4.88411 15.1778 5.8232 14.9177 6.37283 15.4673L8.1177 17.2122C8.66733 17.7618 8.40722 18.7009 7.65313 18.8894L5.32664 19.471Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9218 2.86169C15.6369 2.78157 12.4628 4.05124 10.1393 6.3747L8.04178 8.47221C7.87541 8.63859 7.6557 8.74097 7.4213 8.76135L3.72652 9.08263C2.88004 9.15624 2.50523 10.1852 3.10604 10.786L7.95256 15.6325L12.7991 20.479C13.3999 21.0798 14.4288 20.705 14.5024 19.8585L14.8237 16.1638C14.8441 15.9294 14.9465 15.7097 15.1128 15.5433L17.2104 13.4458C19.5338 11.1223 20.8035 7.94812 20.7234 4.66323C20.6994 3.67839 19.9067 2.88571 18.9218 2.86169ZM14.3166 9.26854C15.0976 10.0496 16.3639 10.0496 17.145 9.26854C17.926 8.48749 17.926 7.22116 17.145 6.44011C16.3639 5.65906 15.0976 5.65906 14.3166 6.44011C13.5355 7.22116 13.5355 8.48749 14.3166 9.26854Z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default Icon
