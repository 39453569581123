import React from 'react'
import styled, { useTheme } from 'styled-components'
import Metamask from 'uikit/components/Svg/Icons/Metamask'
import getExternalLinkProps from '../../util/getExternalLinkProps'
import Box from '../../components/Box/Box'
import getThemeValue from '../../util/getThemeValue'
import Heading from '../../components/Heading/Heading'
import { Button } from '../../components/Button'
import { ModalBody, ModalCloseButton, ModalContainer, ModalHeader, ModalTitle } from '../Modal'
import { connectorLocalStorageKey, walletLocalStorageKey } from './config'
import { Login, ConnectorNames } from './types'

import metamask from './metamask.png'

interface Props {
  login: Login
  onDismiss?: () => void
  displayCount?: number
  t: (key: string) => string
}

const Img = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 16px;
`

const WalletModalBody = styled(ModalBody)`
  padding: 0 22px 37px;
  display: grid;
  row-gap: 32px;
`

const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null, displayCount = 3, t }) => {
  const theme = useTheme()

  return (
    <ModalContainer minWidth="520px">
      <ModalHeader background={getThemeValue('colors.gradients.bubblegum')(theme)}>
        Connect Wallet
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <WalletModalBody>
        <Button
          variant="metamask"
          onClick={() => {
            login(ConnectorNames.Injected)

            localStorage.setItem(walletLocalStorageKey, 'Metamask')
            localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected)
            onDismiss()
          }}
          id="wallet-connect-metamask"
        >
          <Img src={metamask} alt="MetaMask" />
          <span>MetaMask</span>
        </Button>

        <Button
          as="a"
          href="https://docs.pancakeswap.finance/get-started/connection-guide"
          variant="link"
          width="100%"
          {...getExternalLinkProps()}
        >
          Learn how connect
        </Button>
      </WalletModalBody>
    </ModalContainer>
  )
}

export default ConnectModal
