import React from 'react'
import styled from 'styled-components'
import { LogoFooterIcon } from 'uikit'
import { Icon } from 'components/Icon'
import { Social } from 'components/Social'

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: space-between;
  justify-items: center;
  background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' viewBox='0 0 1440 194' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M-172.497 84.9996C-1298 84.9996 -435.497 323.5 -252.997 493C-70.4976 662.499 835.504 455.5 1156 446.5C1476.5 437.5 1349.5 682.5 1407 583.499C1464.5 484.499 1531.5 387 1531.5 362C1531.5 337 1824 -104.757 1460.5 -5.00039C1097 94.7564 953 84.9996 -172.497 84.9996Z' fill='url(%23paint0_linear)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='1531.83' y1='-98.2322' x2='-496.354' y2='878.076' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FC7449'/%3E%3Cstop offset='0.471875' stop-color='%23FD4869'/%3E%3Cstop offset='1' stop-color='%23FC2B51'/%3E%3C/linearGradient%3E%3CclipPath id='clip0'%3E%3Crect width='1440' height='194' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-size: 100% 100%;
  background-size: 100% 194px;
  padding: 114px 56px 45px;
  margin-top: 60px;
`

const Logo = styled.div`
  justify-self: start;
`

const Copy = styled.div`
  justify-self: end;
  color: white;
  font-weight: 500;
  font-size: 16px;
`
const BackGround = styled.div`
  &::after {
    content: '';
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 1255px;
    z-index: -1;
  }
  &::after {
    background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' viewBox='0 0 1440 1255' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M967.501 391.404C716.501 190.904 125.675 90.7433 -66.4999 133.404C-624.302 -367.596 1178.05 -705.638 1360.86 -280.954C1543.67 143.729 1665.53 416.933 1643.5 503.404C1621.47 589.875 1218.5 591.904 967.501 391.404Z' stroke='%23FD4566' stroke-opacity='0.2' stroke-width='2'/%3E%3Cpath d='M967.5 434.5C716.5 234 212.216 277.98 20.0417 320.641C-537.761 -180.359 1178.05 -662.542 1360.86 -237.858C1543.67 186.825 1624.03 573.029 1602 659.5C1579.97 745.971 1218.5 635 967.5 434.5Z' stroke='%23FD4566' stroke-opacity='0.2' stroke-width='2'/%3E%3Cpath d='M808.5 464C557.5 263.5 95.1746 493.339 -97.0001 536C-811.542 -305.142 1269.19 -672.184 1452 -247.5C1634.81 177.184 1462.5 1417 1284.5 1146.5C1198 1015.06 1438 980 1403.5 828.5C1369 677 937.531 567.07 808.5 464Z' stroke='%23FD4566' stroke-opacity='0.2' stroke-width='2'/%3E%3Cpath d='M767.612 569.991C458.724 467.481 59.2865 708.339 -132.888 751C-847.43 -90.1416 1228.3 -566.193 1411.11 -141.509C1593.93 283.175 1421.61 1522.99 1243.61 1252.49C1157.12 1121.05 1035.16 1270.5 1267.11 1003.5C1499.07 736.5 1076.5 672.5 767.612 569.991Z' stroke='%23FD4566' stroke-opacity='0.2' stroke-width='2'/%3E%3Cpath d='M813.329 866.143C51.3289 955.643 -9.67114 945.643 -184.171 915.457C-690.671 1269.96 867.329 1611.14 1033.33 1310.64C1199.33 1010.14 1278.33 927.329 1258.33 866.143C1238.56 805.666 1029.26 835.433 820.662 865.1L813.329 866.143Z' stroke='%23FD4566' stroke-opacity='0.2' stroke-width='2'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='1440' height='1426' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Footer = () => {
  return (
    <Wrapper as="footer">
      <Logo>
        <LogoFooterIcon width="132" height="32" />
      </Logo>
      <Social isFooter />
      <Copy>© Polepup — 2021 COPYRIGHT</Copy>
    </Wrapper>
  )
}

export default Footer
