import React from 'react'
import sprite from './icons.svg'

const param = (size: any): any => (typeof size === 'object' ? size : [size, size])

export const Icon = ({ name, size = [24, 24], viewbox }: any): any => {
  const sizes = param(size)
  const vbox = viewbox ? param(viewbox) : sizes
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={sizes[0]} height={sizes[1]} viewBox={`0 0 ${vbox[0]} ${vbox[1]}`}>
      <use fill="currentColor" xlinkHref={`${sprite}#${name}`} />
    </svg>
  )
}
